import logo from './logo.svg';
import './App.css';
import {Route, Routes} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {socketSync} from "./store/socket/actions";
import store from "./store";
import RequestVehicle from "./pages/RequestVehicle";

function App() {
    const ticket = useSelector((state) => state.parking.ticket);

    useEffect(() => {
        if (ticket.length !== 0) {
            store.dispatch(socketSync());
        }
    }, [ticket]);

    useEffect(() => {
        if (!("Notification" in window)) {
            console.log("This browser does not support desktop notification");
        } else {
            Notification.requestPermission();
        }
    }, []);


  return (
   <>
     <Routes>
       <Route exact path="/parking/:ticket" element={
           <RequestVehicle />
       } />
     </Routes>
       <ToastContainer position="top-right"
                       autoClose={2000} />
   </>
  );
}

export default App;
