import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import store from "../store";
import {getTicketDetails, requestVehicle, verifyPayment} from "../store/parking/actions";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
const queryString = require('query-string');


const Dashboard = () => {
    const params = useParams();
    const parking = useSelector((state) => state.parking);
    const [paymentMethod, setPaymentMethod] = useState("Online");


    useEffect(() => {
        store.dispatch(getTicketDetails(params.ticket));
    }, [params.ticket]);

    useEffect(() => {
        const query = queryString.parse(window.location.search);
        const paymentStatus = query['payment-status'];
        if (paymentStatus !== undefined) {
            if (paymentStatus === 'success' && !parking.paid) {
                store.dispatch(verifyPayment(params.ticket));
            } else if (paymentStatus === 'failed') {
                toast.error("Payment failed", {});
            }
        }

    }, []);



    return (
        <>

            {parking.ticket !== '' && <div className="p-5 shadow-requestVehicle">
                <div className="p-4 rounded-xl bg-linear1 ">
                    <div>
                        <img
                            className="w-full rounded-xl object-cover h-80"
                            src={parking.car.image}
                            alt=""
                        />
                    </div>
                    <div className="grid grid-cols-10 gap-4 my-4">
                        <div className="relative col-span-7 rounded-xl bg-webWhite p-4 shadow-requestVehicleCards">
                            <div className="flex items-center justify-between my-2">
                                <div className="flex items-center float-left">
                                    <div className="bg-purple/10 p-2 rounded-sm w-12 h-10">
                                        <img
                                            className="m-auto"
                                            src="/assets/icons/request-vehicle/level.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="mx-2 text-lg font-bold">Level</div>
                                </div>
                                <div className="float-right flex items-center font-bold text-lg text-purple">
                                    {parking.venue.slots.parking.level.title}
                                </div>
                            </div>
                            <div className="flex items-center justify-between my-2">
                                <div className="flex items-center float-left">
                                    <div className="bg-purple/10 p-2 rounded-sm w-12 h-10">
                                        <img
                                            className="m-auto"
                                            src="/assets/icons/request-vehicle/location.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="mx-2 text-lg font-bold">Spot</div>
                                </div>
                                <div className="float-right flex items-center font-bold text-lg text-purple">
                                    {parking.venue.slots.parking.slot.title}
                                </div>
                            </div>
                            <div className="flex items-center justify-between my-2">
                                <div className="flex items-center float-left">
                                    <div className="bg-purple/10 p-2 rounded-sm w-12 h-10">
                                        <img
                                            className="m-auto"
                                            src="/assets/icons/request-vehicle/key.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="mx-2 text-lg font-bold">Key Slot</div>
                                </div>
                                <div className="float-right flex items-center font-bold text-lg text-purple">
                                    {parking.venue.slots.key.title}
                                </div>
                            </div>
                            <div className="flex items-center justify-between my-2">
                                <div className="flex items-center float-left">
                                    <div className="bg-purple/10 p-2 rounded-sm w-12 h-10">
                                        <img
                                            className="m-auto"
                                            src="/assets/icons/request-vehicle/business.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="mx-2 text-lg font-bold">Payment Mode</div>
                                </div>
                                <div className="float-right flex items-center font-bold text-lg text-purple">
                                    {paymentMethod}
                                </div>
                            </div>
                            <div className="flex items-center justify-between my-2">
                                <div className="flex items-center float-left">
                                    <div className="bg-purple/10 p-2 rounded-sm w-12 h-10">
                                        <img
                                            className="m-auto"
                                            src="/assets/icons/request-vehicle/parking.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="mx-2 text-lg font-bold">Parking Type</div>
                                </div>
                                <div className="float-right flex items-center font-bold text-lg text-purple">
                                    {parking.price.rate}
                                </div>
                            </div>
                            <div className="flex items-center justify-between my-2">
                                <div className="flex items-center float-left">
                                    <div className="bg-purple/10 p-2 rounded-sm w-12 h-10">
                                        <img
                                            className="m-auto"
                                            src="/assets/icons/request-vehicle/card.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="mx-2 text-lg font-bold">Amount</div>
                                </div>
                                <div className="float-right flex items-center font-bold text-lg text-purple">
                                    ${parking.price.amount}
                                </div>
                            </div>
                        </div>
                        <div className=" col-span-3 rounded-xl bg-webWhite p-4 shadow-requestVehicleCards grid grid-rows-4 py-8">
                            <div className="flex items-center float-left">
                                <div className="bg-purple/10 p-2 rounded-sm w-12 h-10">
                                    <img
                                        className="m-auto"
                                        src="/assets/icons/request-vehicle/profile.png"
                                        alt=""
                                    />
                                </div>
                                <div className="mx-2 text-lg font-bold ml-4">{parking.guest.firstName} {parking.guest.lastName}</div>
                            </div>
                            <div className="flex items-center float-left">
                                <div className="bg-purple/10 p-2 rounded-sm w-12 h-10">
                                    <img
                                        className="m-auto"
                                        src="/assets/icons/request-vehicle/keypad.png"
                                        alt=""
                                    />
                                </div>
                                <div className="mx-2 text-lg font-bold ml-4">{parking.car.licensePlate}</div>
                            </div>
                            <div className="flex items-center float-left">
                                <div className="bg-purple/10 p-2 rounded-sm w-12 h-10">
                                    <img
                                        className="m-auto"
                                        src="/assets/icons/request-vehicle/mail.png"
                                        alt=""
                                    />
                                </div>
                                <div className="mx-2 text-lg font-bold ml-4">{parking.guest.email}</div>
                            </div>
                            <div className="flex items-center float-left">
                                <div className="bg-purple/10 p-2 rounded-sm w-12 h-10">
                                    <img
                                        className="m-auto"
                                        src="/assets/icons/request-vehicle/mobile.png"
                                        alt=""
                                    />
                                </div>
                                <div className="mx-2 text-lg font-bold ml-4">{parking.guest.phoneNumber}</div>
                            </div>
                        </div>
                    </div>
                    <div className="rounded-xl bg-webWhite p-4 shadow-requestVehicleCards my-4">
                        <div className="font-bold">Ticket #{parking.ticket}</div>
                        <div className="flex items-center justify-between my-2">
                            <div className="flex items-center float-left text-5xl font-semibold">
                                {parking.price.amount} <span className="text-[#17212B] ml-3"> $</span>
                            </div>
                        </div>
                    </div>
                    <div className="rounded-xl bg-webWhite p-4 shadow-requestVehicleCards my-4 grid grid-cols-2 justify-center items-center">
                        <div className="flex justify-center items-center">
                            <input
                                className="mx-4 text-[#667483]"
                                type="radio"
                                value="Offline"
                                name="payment_method"
                                onChange={(e) => {
                                   setPaymentMethod(e.currentTarget.value);
                                }}
                            />
                            <div>
                                <p className="text-lg font-bold">Pay on Delivery</p>
                                <p>Pay when picking your vehicle</p>
                            </div>
                        </div>
                        <div className="flex justify-center items-center">
                            <input
                                className="mx-4 text-[#667483]"
                                type="radio"
                                value="Online"
                                name="payment_method"
                                onChange={(e) => {
                                    setPaymentMethod(e.currentTarget.value);
                                }}
                            />
                            <div>
                                <p className="text-lg font-bold">Online Payment</p>
                                <p>Pay now using credit/debit card</p>
                            </div>
                        </div>{" "}



                    </div>

                    { !parking.requested && <button onClick={() => {
                        store.dispatch(requestVehicle(params.ticket, paymentMethod));
                    }} className="font-bold w-full rounded-md bg-purple text-webWhite py-2 text-xl my-4">
                        Request Vehicle
                    </button>}
                    { (parking.requested && !parking.delivered) && <button className="font-bold w-full rounded-md bg-sky-400 text-webWhite py-2 text-xl my-4">
                        Vehicle has been requested
                    </button>}
                    { parking.delivered && <button className="font-bold w-full rounded-md bg-green text-webWhite py-2 text-xl my-4">
                        Your vehicle has been delivered
                    </button>}
                </div>
            </div>}

        </>
    );
}

export default Dashboard;
