import {
    ticketDetailLoaded,
    ticketDetailLoadFailed,
    ticketPaymentSuccess,
    ticketVehicleRequestedOffline,
    trackingDetailLoaded,
} from "./reducers";
import axios, {AxiosError} from "axios";
import baseConfig from "../../Config";
import store from "../index";

export const getTicketDetails = (ticket) => async (dispatch) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": '',
        },
    };

    try {
        let res = await axios.get(
            `${baseConfig.apiBaseUrl}/user/parking/${ticket}`,
            config
        );
        if (res.data['status']) {
            dispatch({
                type: ticketDetailLoaded,
                payload: res.data,
            });
        }
        else {
            dispatch({
                type: ticketDetailLoadFailed,
                payload: res.data,
            });
        }
    }  catch(err) {
        if (err.response) {
            dispatch({
                type: ticketDetailLoadFailed,
                payload: err.response.data,
            });
        }

    }

}

export const getTrackingDetails = (ticket) => async (dispatch) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": '',
        },
    };

    try {
        let res = await axios.get(
            `${baseConfig.apiBaseUrl}/user/parking/${ticket}/tracking`,
            config
        );
        if (res.data['status']) {
            dispatch({
                type: trackingDetailLoaded,
                payload: {
                    ticket,
                    timings: res.data.data,
                },
            });
        }
        else {
            dispatch({
                type: ticketDetailLoadFailed,
                payload: res.data,
            });
        }
    }  catch(err) {
        if (err.response) {
            dispatch({
                type: ticketDetailLoadFailed,
                payload: err.response.data,
            });
        }

    }

}

export const requestVehicle = (ticket, paymentMethod) => async (dispatch) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": '',
        },
    };

    try {
        let res = await axios.get(
            `${baseConfig.apiBaseUrl}/user/parking/${ticket}/request-vehicle?payment-method=${paymentMethod}`,
            config
        );
        console.log(res);
        if (res.data['status']) {
            store.dispatch(getTrackingDetails(ticket));
           if (paymentMethod === 'Online') {
               window.open(res.data.data,"_self")
           } else {
               dispatch({
                   type: ticketVehicleRequestedOffline,
                   payload: {
                       status: true,
                       message: `Request to deliver vehicle has been initiated`,
                       url: res.data.data
                   },
               });
           }
        }
        else {
            dispatch({
                type: ticketDetailLoadFailed,
                payload: res.data,
            });
        }
    }  catch(err) {
        if (err.response) {
            dispatch({
                type: ticketDetailLoadFailed,
                payload: err.response.data,
            });
        }

    }

}

export const verifyPayment = (ticket) => async (dispatch) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": '',
        },
    };

    try {
        let res = await axios.get(
            `${baseConfig.apiBaseUrl}/user/parking/${ticket}/verify-payment`,
            config
        );
        console.log(res);
        if (res.data['status']) {
            dispatch({
                type: ticketPaymentSuccess,
                payload: res.data,
            });
        }
        else {
            dispatch({
                type: ticketDetailLoadFailed,
                payload: res.data,
            });
        }
    }  catch(err) {
        if (err.response) {
            dispatch({
                type: ticketDetailLoadFailed,
                payload: err.response.data,
            });
        }

    }

}
