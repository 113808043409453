import io from "socket.io-client";
import store from "../index";
import baseConfig from "../../Config";
import {vehicleParked, vehicleStatusUpdate} from "../parking/reducers";
import {useSelector} from "react-redux";
import {getTrackingDetails} from "../parking/actions";


export const socketSync = () => (dispatch) => {
    const socket = io(baseConfig.socketBaseUrl);


    socket.on('connect', () => {
        console.log("CONNECTED");
    });

    socket.on('disconnect', () => {
        console.log("DISCONNECTED");
    });


    socket.on('userParkingUpdate', (arg) => {
        store.dispatch(getTrackingDetails(arg.data.ticket))
        store.dispatch(syncParking(arg));
    });
}

export const syncParking = (body) => (dispatch) => {
    switch (body.type) {
        case 'vehicleParked':
            dispatch({
                type: vehicleParked,
                payload: body,
            });
            break;
        case 'vehicleStatusUpdate':
            dispatch({
                type: vehicleStatusUpdate,
                payload: body,
            });
            break;
    }
}

