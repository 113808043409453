import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';


const initialState = { car: {}, guest: {}, venue: {}, price: {}, timings: {}, status: '', paid: false, ticket: '', requested: false, delivered: false, paymentUrl: '' }

const parkingSlice = createSlice({
    name: 'parking',
    initialState,
    reducers: {
        ticketDetailLoaded(state, action) {
            state.ticket = action.payload.data.ticket;
            state.delivered = action.payload.data.delivered;
            state.requested = action.payload.data.requested;
            state.car = action.payload.data.car;
            state.timings = action.payload.data.timings;
            state.guest = action.payload.data.guest;
            state.venue = action.payload.data.venue;
            state.price = action.payload.data.price;
            state.status = action.payload.data.status;
            state.paymentUrl = action.payload.data.paymentUrl;
            state.paid = action.payload.data.paymentStatus === 'Paid';
        },
        ticketDetailLoadFailed(state, action) {
            toast.error(action.payload.message, {});
        },
        ticketPaymentSuccess(state, action) {
            state.paid = true;
            state.requested = true;
            state.paymentUrl = '';
            toast.success(action.payload.message, {});
            // const options = {
            //     body: action.payload.message,
            //     icon: state.car.image,
            //     dir: "ltr"
            // };
            // new Notification("Ivalet", options);
        },
        ticketVehicleRequestedOffline(state, action) {
            state.paid = false;
            state.requested = true;
            state.paymentUrl = action.payload.url;
            toast.success(action.payload.message, {});
            // const options = {
            //     body: action.payload.message,
            //     icon: state.car.image,
            //     dir: "ltr"
            // };
            // new Notification("Ivalet", options);
        },
        vehicleParked(state, action) {
            if (action.payload.data.ticket === state.ticket) {
                state.status = action.payload.data.status;
                state.venue = action.payload.data.venue;
                toast.success(action.payload.message, {});
                // const options = {
                //     body: action.payload.message,
                //     icon: state.car.image,
                //     dir: "ltr"
                // };
                // new Notification("Ivalet", options);
            }
        },
        vehicleStatusUpdate(state, action) {
            if (action.payload.data.ticket === state.ticket) {
                state.delivered = action.payload.data.delivered;
                state.status = action.payload.data.status;
                toast.success(action.payload.message, {});
                // const options = {
                //     body: action.payload.message,
                //     icon: state.car.image,
                //     dir: "ltr"
                // };
                // new Notification("Ivalet", options);
            }
        },
        trackingDetailLoaded(state, action) {
            if (action.payload.ticket === state.ticket) {
                state.timings = action.payload.timings;
            }
        },
    },
})

export const { ticketDetailLoaded, ticketDetailLoadFailed, ticketPaymentSuccess, ticketVehicleRequestedOffline, vehicleParked, vehicleStatusUpdate, trackingDetailLoaded } = parkingSlice.actions
export default parkingSlice.reducer
